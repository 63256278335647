
import Storage from "@/util/Storage"
import {AccountSuc, AccountWater} from "@/Api/Money";
import {WalletStore} from "@/views/Money/Wallet/Store";
import moment from "moment";

export function getAccount() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const { startDate,endDate } = WalletStore.getWalletUpData
    return Promise.resolve(AccountSuc({ userId,token },{ startDate:moment(startDate).format("YYYY-MM-DD"),endDate:moment(endDate).format("YYYY-MM-DD") }).then(res=>{
        return res.data
    }))
}


export function getWater() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( AccountWater({ userId,token },WalletStore.getWalletUpData).then(res=>{
        return res.data
    }) )
}
