

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import WalletStoreType from "@/views/Money/Wallet/Store/indexType";
import {AccountWaterType} from "@/Api/Money/indexType";

@Module({
    name:"Wallet",
    store,
    dynamic: true,
})
export default class Wallet extends VuexModule implements WalletStoreType{
    WalletList: any[] = [];
    get getWalletList(){ return this.WalletList }
    @Mutation
    SetWalletList(data: any[]): void {
        this.WalletList = data
    }

    WalletUpData: AccountWaterType = {
        endDate: "", startDate: "",
        pageNo: 1, pageSize: 15
    };
    get getWalletUpData(){ return this.WalletUpData }
    @Mutation
    SetWalletUpData(data: AccountWaterType): void {
        this.WalletUpData = data
    }

    WalletFilterBool = false;
    get getWalletFilterBool(){ return this.WalletFilterBool }
    @Mutation
    SetWalletFilterBool(data: boolean): void {
        this.WalletFilterBool = data
        if ( data ){
            let time = setTimeout(()=>{
                this.WalletFilterBool = false
                clearTimeout(time)
            },1000)
        }
    }
}

export const WalletStore = getModule( Wallet )
