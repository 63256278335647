




export const depositList = [
    {
        money:"￥ 0",
        value:"已提现",
        border:true
    },{
        money:"￥ 0",
        value:"已到账",
        border:true
    },{
        money:"￥ 0",
        value:"未到账",
        border:false
    },
]
